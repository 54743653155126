import React, { useContext } from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import { I18NContext } from "../i18n/Context";

const ViewInFullScreenLink = styled.a`
  ${tw`block text-center mt-8 mx-auto mb-0 px-base bg-white border border-black text-black py-sm rounded-full`}
`;

const ViewInFullScreenLinkContainer = styled.div`
  ${tw`w-full px-4 md:w-1/3 mx-auto`}
`;

const StyledMatterportContainer = styled.div`
  ${tw`w-full md:w-4/5 md:mx-auto my-base text-center`}
`;

const StyledMatterportContainerTitle = styled.div`
  ${tw`w-full md:mx-auto text-center mb-2 md:text-3xl text-xl`}
  max-width: 64rem;
  font-weight: bold;
`;

const StyledMatterportContainerSubtitle = styled.div`
  ${tw`w-full md:mx-auto text-center`}
  color: white;
  max-width: 64rem;
  font-weight: bold;
  line-height: 2.5;
  ${(props) => {
    switch (props.title_background) {
      case "primary-lighter":
        return tw`bg-primary-lighter`;
      case "primary-normal":
        return tw`bg-primary-normal`;
      case "primary-darker":
        return tw`bg-primary-darker`;
      case "black":
        return tw`bg-black`;
      default:
        return tw`bg-white`;
    }
  }}
`;

const Matterport = ({ matterport_id, title, title_background }) => {
  const { language } = useContext(I18NContext);
  return (
    <StyledMatterportContainer>
      {title ? (
        <StyledMatterportContainerTitle>{title}</StyledMatterportContainerTitle>
      ) : null}
      <StyledMatterportContainerSubtitle title_background={title_background}>
        Tour virtual 3d
      </StyledMatterportContainerSubtitle>
      <iframe
        width="100%"
        height="580"
        style={{
          maxWidth: "64rem",
          margin: "0 auto",
          position: "relative",
        }}
        title={`Matterport ${matterport_id}`}
        src={`https://my.matterport.com/show/?m=${matterport_id}&help=1&lang=${language}`}
        frameborder="0"
        allowfullscreen
        allow="xr-spatial-tracking"
      ></iframe>
      <ViewInFullScreenLinkContainer>
        <ViewInFullScreenLink
          target="_blank"
          href={`https://my.matterport.com/show/?m=${matterport_id}&help=1&lang=${language}&play=1`}
        >
          {language === "spanish"
            ? "Ver Tour virtual en pantalla completa"
            : "View Virtual Tour in Fullscreen"}
        </ViewInFullScreenLink>
      </ViewInFullScreenLinkContainer>
    </StyledMatterportContainer>
  );
};

export default Matterport;
