import React from "react";
import CloudinaryUtils from "../CloudinaryUtils";

const VideoBannerWithCaption = ({ videoUrl, videoType }) => {

    return (
        <div className="flex flex-wrap h-screen" >
            <video muted autoPlay={true} loop src={videoUrl} />
        </div>
    )
};

export default VideoBannerWithCaption;
