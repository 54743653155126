import React from "react";
import * as R from "ramda";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import TextSection from "../../Text";
import styled from "@emotion/styled";
import Image from "gatsby-image";
import Heading from "../../Heading/Heading";
import CloudinaryBackgroundImage from "../../CloudinaryBackgroundImage";

const GetFluidMainImage = (pageDocument) => {
  const imageFile = R.path(["main_image", "imageFile"], pageDocument);
  console.log("pageDocument: ", pageDocument);
  let source;
  if (
    imageFile &&
    ((imageFile.cloudinary && imageFile.cloudinary.fluid) ||
      (imageFile.mobileImage &&
        imageFile.desktop80QualityImage &&
        imageFile.desktopImage))
  ) {
    source =
      imageFile && imageFile.cloudinary && imageFile.cloudinary.fluid
        ? imageFile.cloudinary.fluid
        : [
            imageFile.mobileImage.fluid,
            imageFile.desktopImage.fluid,
            {
              ...imageFile.desktop80QualityImage.fluid,
              media: `(min-width: 768px)`,
            },
          ];
  }

  return source;
};

const RelatedPageIconContainer = styled.div`
  ${tw`w-full md:text-center`}
`;

const MainImageContainer = styled.div`
  ${tw`mb-base md:mb-0`};
  ${(props) => {
    if (props.number_of_pages === 1) {
      return tw`py-base px-sm md:px-0`;
    }
    return tw`py-xsm px-sm`;
  }}
`;

const StyledBackgroundImage = styled(CloudinaryBackgroundImage)`
  ${(props) => {
    if (props.number_of_pages === 1) {
      return tw`w-full overflow-hidden mx-auto rounded-xl`;
    } else {
      return tw`w-full overflow-hidden mx-auto rounded-xl`;
    }
  }};
  background-size: cover;
  background-position: center center;
`;

const StyledCardContentContainer = styled.div`
  ${tw`w-full my-sm text-justify`}
`;

const HeadingContainer = styled.h1`
  ${tw`text-lg text-purple-900 font-bold`}
`;

const StyledDescription = styled.div`
  ${tw`w-full py-sm font-bold text-gray-700`};
  strong {
    ${tw`text-gray-900`}
  }
  ${(props) => {
    if (props.number_of_pages === 1) {
      return tw`text-center`;
    }
    return tw`text-justify`;
  }}
`;

const RelatedPageMore = styled.button`
  ${tw`underline text-left`}
`;

const RelatedPageV2 = ({ page, number_of_pages, language }) => {
  const pageData = R.pick(
    ["uid", "id", "tags"],
    R.path(["related_page"], page),
  );
  const pageDocument = R.path(
    ["data"],
    R.path(["related_page", "document"], page)[0],
  );

  const navigationLink =
    language === "english" ? `/en/${pageData.uid}` : `/${pageData.uid}`;
  const navigationLabel = language === "english" ? `See more` : "Conoce más";
  const mainImageUrl = R.path(["main_image", "url"], pageDocument);
  const minHeight = number_of_pages === 1 ? "70vh" : "50vh";

  return (
    <Link to={navigationLink}>
      <MainImageContainer number_of_pages={number_of_pages}>
        <StyledBackgroundImage
          url={mainImageUrl}
          background_size={"cover"}
          background_size_mobile={"cover"}
          number_of_pages={number_of_pages}
          background_height={minHeight}
          image_width={640}
          image_height={480}
          quality={80}
        />
        <StyledCardContentContainer>
          <HeadingContainer>{pageDocument.title.text}</HeadingContainer>
          <StyledDescription
            dangerouslySetInnerHTML={{
              __html: pageDocument.short_description.html,
            }}
          ></StyledDescription>
          <div>
            <RelatedPageMore>{navigationLabel}</RelatedPageMore>
          </div>
        </StyledCardContentContainer>
      </MainImageContainer>
    </Link>
  );
};

export default RelatedPageV2;
