import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const SEO = ({ title, description, image, titleTemplate, language }) => {
  const seo = {
    title,
    description,
    image,
    titleTemplate,
    article: false,
  };
  return (
    <React.Fragment>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <html lang={language || "es"} />
        <meta http-equiv="content-language" content={language || "es"} />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        <meta
          property="og:type"
          content={seo.article ? "article" : "website"}
        />
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
        <meta name="twitter:card" content="summary_large_image" />
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}
      </Helmet>
    </React.Fragment>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathName: PropTypes.string,
};

export default SEO;

/*

return (
        <StaticQuery
            query={query}
            render={(
                {
                    site: {
                        siteMetadata: {
                            defaultTitle,
                            titleTemplate,
                            defaultImage,
                            defaultDescription,
                            siteUrl,
                        }
                    }
                }
            ) => {


                return (

                )
            }}
        >
        </StaticQuery>
 */
