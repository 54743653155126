import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Img from "gatsby-image";

import { css } from "@emotion/core";
import TextSection from "../Text";
import Heading from "../Heading/Heading";
const HighlightSectionContainer = styled.div`
  ${tw`flex flex-wrap py-sm md:w-4/5 mx-auto md:py-sm md:pl-xxlg`}
`;
const HighlightSectionImageContainer = styled.div`
  ${tw`w-full my-auto mx-auto`}
`;

const HighlightSectionTextContainer = styled.div`
  ${tw`w-full md:w-2/3 px-lg my-base md:my-0`}
`;

const HighlightTitle = styled.div`
  ${tw`text-xl md:text-2xl uppercase text-primary-dark`}
`;

const HighlightDescriptionContainer = styled.div`
  ${tw`flex flex-wrap text-justify`}
`;

const HighlightStyledImage = styled.img`
  ${tw`w-auto px-xsm md:my-0 my-base h-32 md:h-64 md:w-64 md:mx-0 mx-auto my-auto`}
  object-fit: contain;
`;

const parseFluidImageFile = (imageFile) => {
  console.log("parseFluidImageFile: ", imageFile);
  return [
    imageFile.mobileImage.fluid,
    imageFile.desktopImage.fluid,
    {
      ...imageFile.desktop80QualityImage.fluid,
      media: `(min-width: 768px)`,
    },
  ];
};

const HighlightSection = ({
  highlight_photo,
  highlight_description,
  highlight_title,
}) => {
  return (
    <HighlightSectionContainer>
      {highlight_photo && highlight_photo.url ? (
        <HighlightStyledImage src={highlight_photo.url} />
      ) : null}
      <HighlightSectionTextContainer>
        <HighlightTitle>
          <Heading heading={highlight_title} />
        </HighlightTitle>
        <HighlightDescriptionContainer>
          <TextSection
            padding_x={"0"}
            padding_y={"0"}
            padding_x_md={"0"}
            padding_y_md={"0"}
            margin_x={"0"}
            margin_x_md={"0"}
            section_text={highlight_description}
          />
        </HighlightDescriptionContainer>
      </HighlightSectionTextContainer>
    </HighlightSectionContainer>
  );
};

export default HighlightSection;
