import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import Lightbox from "react-image-lightbox";
import HeroV2 from "./HeroV2";
import HeroV4 from "./HeroV4";
import "react-image-lightbox/style.css";

import tw from "tailwind.macro";
import * as R from "ramda";
import BackgroundImage from "gatsby-background-image";
import { css } from "@emotion/core";
import BannerCTA from "./BannerCTA";
import CloudinaryBackgroundImage from "../CloudinaryBackgroundImage";
import useScreenType from "react-hooks-screen-type";
import { all } from "ramda";
import { I18NContext } from "../i18n/Context";
import { navigate } from "gatsby";

const BannerWithCaptionWrapperContainer = styled.div`
  ${tw`flex justify-center xl:h-auto w-full h-full items-center text-center`}
`;

const BannerWithCaptionContainer = styled.div`
  ${tw`flex flex-col xl:w-10/12 xl:pt-xxlg h-auto px-base xl:bg-transparent`}
  background: rgba(0,0,0,.45);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.59);
`;

const BannerWithCaptionTitle = styled.div`
  ${tw`xl:text-6xl text-3xl leading-none text-white xl:py-xsm py-sm`}
`;

const BannerWithCaptionDescription = styled.div`
  ${tw`xl:text-3xl text-xl font-hairline xl:py-6 py-3 text-white`}
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.59);
`;

const marginY = (margin) => {
  switch (margin) {
    case "base":
      return tw`my-base`;
    case "xxsm":
      return tw`my-xxsm`;
    case "xsm":
      return tw`my-xsm`;
    case "sm":
      return tw`my-sm`;
    case "lg":
      return tw`my-lg`;
    case "xlg":
      return tw`my-xlg`;
    case "auto":
      return tw`my-auto`;
    default:
      return tw`my-base`;
  }
};

const StyleBannerWithCaptionContainer = styled.div`
  ${(props) => {
    switch (props.margin_y) {
      case "base":
        return tw`my-base`;
      case "xxsm":
        return tw`my-xxsm`;
      case "xsm":
        return tw`my-xsm`;
      case "sm":
        return tw`my-sm`;
      case "lg":
        return tw`my-lg`;
      case "xlg":
        return tw`my-xlg`;
      case "auto":
        return tw`my-auto`;
      case "0":
        return tw`my-0`;
      default:
        return tw`my-0`;
    }
  }}
  ${(props) => {
    return props.allow_zoom ? tw`cursor-pointer` : tw`cursor-default`;
  }}
`;

const ZoomImageButton = styled.button`
  ${tw`mx-auto mb-0 px-base bg-white border border-black text-black py-sm rounded-full`}
`;

const ZoomImageButtonContainer = styled.div`
  ${tw`flex w-full h-full my-sm text-center`}
`;

const BannerCTAContainer = styled.div``;

const BannerWithCaption = ({
  image_banner,
  image_banner_mobile,
  image_banner_title,
  image_banner_subtitle,
  image_banner_description,
  background_repeat,
  banner_image_size,
  background_height,
  image_banner_cta,
  padding,
  margin,
  is_parallax,
  allow_zoom,
  iconOverlay,
  image_banner_call_to_action,
  template,
  socialLinks,
}) => {
  let bannerCta;
  if (image_banner_cta) {
    bannerCta = R.pipe(
      R.path(["document"]),
      (document) => document[0],
      R.path(["data"]),
    )(image_banner_cta);
  }

  let callToAction;
  if (image_banner_call_to_action) {
    callToAction = R.pipe(
      R.path(["document"]),
      (document) => document[0],
      R.path(["data"]),
    )(image_banner_call_to_action);
  }

  const screenType = useScreenType();
  const [toggler, setToggler] = useState(false);
  const { language } = useContext(I18NContext);
  const toggleLightBox = () => {
    if (allow_zoom) {
      setToggler(!toggler);
    }
  };

  const imageOnly = !image_banner_title.html && !image_banner_description.html;
  let mobileHeight;
  if (background_height) {
    const mobileHeightNumber = parseInt(background_height.replace("vh", ""));
    if (mobileHeightNumber >= 90) {
      mobileHeight = `${mobileHeightNumber - 40}vh`;
    }
  }

  const getImageToRender = () => {
    console.log("Image banner getImageToRender");
    if (
      (screenType === "xSmall" || screenType === "small") &&
      image_banner_mobile &&
      image_banner_mobile.url
    ) {
      console.log("Screen Type Small or xSmall ", image_banner_mobile);
      return image_banner_mobile.url;
    }
    return image_banner.url;
  };

  if (template === "v1" || template === "v3" || !template) {
    return (
      <React.Fragment>
        {toggler && (
          <Lightbox
            mainSrc={getImageToRender()}
            onCloseRequest={() => toggleLightBox()}
          />
        )}
        <StyleBannerWithCaptionContainer
          allow_zoom={allow_zoom}
          onClick={() => toggleLightBox()}
          margin_y={margin}
        >
          <CloudinaryBackgroundImage
            url={getImageToRender()}
            background_size={banner_image_size}
            background_height={background_height}
            is_parallax={is_parallax}
          >
            <BannerWithCaptionWrapperContainer>
              <BannerWithCaptionContainer>
                {image_banner_title && image_banner_title.html ? (
                  <BannerWithCaptionTitle
                    dangerouslySetInnerHTML={{
                      __html: image_banner_title.html,
                    }}
                  />
                ) : null}
                {image_banner_description && image_banner_description.html ? (
                  <BannerWithCaptionDescription
                    dangerouslySetInnerHTML={{
                      __html: image_banner_description.html,
                    }}
                  />
                ) : null}
                {false && (
                  <BannerCTAContainer>
                    <BannerCTA {...bannerCta} />
                  </BannerCTAContainer>
                )}
              </BannerWithCaptionContainer>
            </BannerWithCaptionWrapperContainer>
          </CloudinaryBackgroundImage>
          {allow_zoom && (
            <ZoomImageButtonContainer>
              <ZoomImageButton>
                {language === "spanish"
                  ? "Ver imágen en Pantalla completa"
                  : "View image in Fullscreen"}
              </ZoomImageButton>
            </ZoomImageButtonContainer>
          )}
        </StyleBannerWithCaptionContainer>
      </React.Fragment>
    );
  }

  if (template === "v2") {
    return (
      <HeroV2
        bannerTitle={image_banner_title}
        bannerDescription={image_banner_description}
        bannerImage={getImageToRender()}
        bannerSubtitle={image_banner_subtitle}
      />
    );
  }

  if (template === "v4") {
    return (
      <HeroV4
        image_banner_title={image_banner_title}
        image_banner_description={image_banner_description}
        bannerImage={getImageToRender()}
        iconOverlay={iconOverlay}
        margin={margin}
        banner_image_size={banner_image_size}
        background_height={background_height}
        is_parallax={is_parallax}
        callToAction={callToAction}
        socialLinks={socialLinks}
      />
    );
  }
};

export default BannerWithCaption;
