import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import Heading from "../Heading/Heading";
import * as R from "ramda";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import FeaturesCarousel from "./FeaturesCarousel";
import CloudinaryBackgroundImage from "../CloudinaryBackgroundImage";
import FeaturesBulletsSection from "./FeaturesBulletsSection";

const StyledFeaturesContainer = styled.div`
  ${tw`flex flex-wrap py-base px-xsm md:py-xlg`}
  ${(props) => {
    switch (props.background_color) {
      case "gray":
        switch (props.color_tone) {
          case "100":
            return tw`bg-gray-100`;
          case "200":
            return tw`bg-gray-200`;
          case "300":
            return tw`bg-gray-300`;
          case "400":
            return tw`bg-gray-400`;
          case "500":
            return tw`bg-gray-500`;
          default:
            return tw`bg-gray-100`;
        }
      default:
        return tw`bg-white`;
    }
  }};
`;

const FeaturesItemsContainers = styled.div`
  ${tw`flex flex-wrap mx-auto w-full md:px-lg`};
`;

const StyledFeatureElement = styled.div`
  ${tw`text-center`}
  ${(props) => {
    const columnsToUse = props.default_columns_per_row || props.total_elements;
    switch (columnsToUse) {
      case 1:
        return tw`w-full`;
      case 2:
        return tw`w-1/2 md:w-1/2 mx-auto`;
      case 3:
        return tw`w-1/2 md:w-1/3 mx-auto`;
      case 4:
        return tw`w-1/2 md:w-1/4 mx-auto`;
      case 5:
        return tw`w-1/2 md:w-1/3 mx-auto`;
      case 6:
        return tw`w-1/2 md:w-1/3 mx-auto`;
      case 7:
        return tw`w-1/2 md:w-1/3 mx-auto`;
      default:
        return tw`w-1/2 md:w-1/4 mx-auto`;
    }
  }}
`;

const FeatureSectionTitleContainer = styled.div`
  ${tw`w-full px-sm md:px-0 text-center md:text-center mb-base`}
`;

const StyledFeatureTitle = styled.div`
  p {
    ${tw`text-gray-900 text-lg md:text-xl py-sm break-words md:py-sm`}
  }
`;

const StyledFeatureDescription = styled.div`
  ${tw`text-center`}
`;

const StyledImage = styled(Img)`
  ${tw`rounded`}
`;

const StyledIcon = styled(BackgroundImage)`
  ${tw`h-32 w-auto`};
  background-size: contain;
`;

const getFeaturedElementDocument = (pathString, pickOptions) =>
  R.pipe(
    R.path([`${pathString}`, "document"]),
    (result) => result[0],
    R.path(["data"]),
  );

const Features = ({
  section_background_color,
  features_title,
  features,
  section_color_tone,
  default_columns_per_row,
  template,
}) => {
  return (
    <StyledFeaturesContainer
      background_color={section_background_color}
      color_tone={section_color_tone}
    >
      {features_title && features_title.html ? (
        <FeatureSectionTitleContainer>
          <Heading heading={features_title} />
        </FeatureSectionTitleContainer>
      ) : null}

      {features && features.length && (template === "v1" || !template) && (
        <FeaturesItemsContainers>
          {features.map((featureElement) => {
            console.log("featureElement: ", featureElement);
            const parsedElement = featureElement;
            const parsedElementDescription = R.path(
              ["feature_description"],
              parsedElement,
            );
            const imageFile = R.path(
              ["feature_image", "imageFile"],
              parsedElement,
            );
            const iconImageFile = R.path(
              ["feature_icon", "imageFile"],
              parsedElement,
            );
            const iconImageUrl = R.path(["feature_icon", "url"], parsedElement);
            let sources;
            let iconSources;
            if (
              imageFile &&
              imageFile.mobileImage &&
              imageFile.desktop80QualityImage &&
              imageFile.desktopImage
            ) {
              sources = [
                imageFile.mobileImage.fluid,
                imageFile.desktopImage.fluid,
                {
                  ...imageFile.desktop80QualityImage.fluid,
                  media: `(min-width: 768px)`,
                },
              ];
            }

            console.log("Icon Sources: ", iconSources);

            return (
              <StyledFeatureElement
                total_elements={features.length}
                default_columns_per_row={default_columns_per_row}
              >
                {sources && sources.length ? (
                  <StyledImage fluid={sources} />
                ) : null}
                {iconImageUrl && (
                  <CloudinaryBackgroundImage
                    background_height={"60px"}
                    image_height={60}
                    image_width={45}
                    quaility={100}
                    url={iconImageUrl}
                    background_size={"contain"}
                  />
                )}
                {R.path(["feature_title", "html"]) ? (
                  <StyledFeatureTitle
                    dangerouslySetInnerHTML={{
                      __html: R.path(["feature_title", "html"], parsedElement),
                    }}
                  />
                ) : null}
                {parsedElementDescription &&
                parsedElementDescription["html"] ? (
                  <StyledFeatureDescription
                    dangerouslySetInnerHTML={{
                      __html: R.path(["html"], parsedElementDescription),
                    }}
                  ></StyledFeatureDescription>
                ) : null}
              </StyledFeatureElement>
            );
          })}
        </FeaturesItemsContainers>
      )}
      {features && features.length && template === "v2" && (
        <FeaturesItemsContainers>
          <FeaturesCarousel features={features} />
        </FeaturesItemsContainers>
      )}
      {features && features.length && template === "bullets_section" && (
        <div
          css={css`
            ${tw`px-sm md:px-0`}
          `}
        >
          <FeaturesBulletsSection features={features} />
        </div>
      )}
    </StyledFeaturesContainer>
  );
};

export default Features;
