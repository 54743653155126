import React, { useContext } from "react";
import { ImageGalleryContext } from "./Context";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import BackgroundImage from "gatsby-image";
import { css } from "@emotion/core";
import { navigate } from "gatsby";
import CloudinaryBackgroundImage from "../CloudinaryBackgroundImage";

const ImageGalleryModalContainer = styled.div`
  ${tw`flex flex-wrap w-full z-50 fixed top-0 bottom-0 bg-white overflow-y-scroll`}
`;

const ImageGalleryContentContainer = styled.div`
  ${tw`w-full my-xlg `}
`;

const ImageGalleryContent = styled.div`
  ${tw`w-full mx-auto`}
`;

const ImageGalleryImageElementContainer = styled.div`
  ${tw`w-full md:w-3/4 mx-auto pb-sm`}
  height: auto;
`;

const ImageGalleryImageElementImage = styled.div`
  ${tw`h-full`}
`;

const ImageGalleryCallToActionContainer = styled.div`
  ${tw`w-full fixed bottom-0 bg-white border-t py-xsm text-center`}
`;

const ImageGalleryHeader = styled.div`
  ${tw`flex flex-wrap w-full fixed top-0 border-b p-xsm z-30 bg-white`}
`;

const ImageGalleryHeaderDismissContainer = styled.div`
  ${tw`w-full content-end text-right pr-sm`}
`;

const ImageGalleryModal = () => {
  const { galleryImages, hideModal } = useContext(ImageGalleryContext);
  const images = [galleryImages.mainImage].concat(galleryImages.desktopGrid);
  if (images && images.length) {
    return (
      <ImageGalleryModalContainer>
        <ImageGalleryHeader>
          <ImageGalleryHeaderDismissContainer>
            <button
              onClick={() => hideModal()}
              css={css`
                ${tw`text-3xl font-thin`}
              `}
            >
              X
            </button>
          </ImageGalleryHeaderDismissContainer>
        </ImageGalleryHeader>
        <ImageGalleryContentContainer>
          <ImageGalleryContent>
            {images.map(image => {
              if (image) {
                return (
                  <ImageGalleryImageElementContainer>
                    <ImageGalleryImageElementImage>
                      <CloudinaryBackgroundImage
                          url={image.url}
                          image_width={1024}
                          image_height={768}
                          quaility={80}
                          background_height={"70vh"}
                          background_size={"cover"}
                      />
                    </ImageGalleryImageElementImage>
                  </ImageGalleryImageElementContainer>
                );
              }
            })}
          </ImageGalleryContent>
        </ImageGalleryContentContainer>
      </ImageGalleryModalContainer>
    );
  }

  return <div />;
};

export default ImageGalleryModal;
