import React from 'react';
import styled from "@emotion/styled";
import CloudinaryBackgroundImage from "../../CloudinaryBackgroundImage";

const StyledMarker = styled.div`
  height: 60px;
  width: 60px;
}`;

const Marker = () => (
    <React.Fragment>
        <StyledMarker>
            <CloudinaryBackgroundImage
                url={"https://images.prismic.io/xalettulum/081b743c-7f7f-4858-ab59-294637ed436a_iconfinder_map-marker_299087.png?auto=compress,format"}
                background_height={"10vh"}
                background_size={"contain"}
            />
        </StyledMarker>
    </React.Fragment>
);

export default Marker;
