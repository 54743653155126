import React, { useContext, useState, useRef } from "react";
import { func, string } from "prop-types";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import { Formik, Form, Field } from "formik";
import { navigate } from "gatsby";
import styled from "@emotion/styled";
import * as axios from "axios";
import * as Yup from "yup";
import Heading from "../Heading/Heading";
import TextSection from "../Text";
import { I18NContext } from "../i18n/Context";

const FieldContainer = styled.div`
  ${tw`
        mb-4
    `}
`;

const TextInputCSS = css`
  ${tw`rounded-full appearance-none border text-sm border-gray-600 w-full text-justify py-xsm px-sm text-black leading-tight`}
`;
const TextInputCSSV2 = css`
  ${tw`appearance-none border text-sm border-gray-400 w-full text-justify py-xsm px-sm text-black leading-tight shadow-md`}
`;
const SelectInputCSS = css`
  ${tw`block rounded-full appearance-none w-full text-sm bg-white border text-justify border-gray-600 py-xsm px-sm text-black leading-tight`}
`;

const SelectInputCSSV2 = css`
  ${tw`block appearance-none w-full text-sm bg-white border text-justify border-gray-400 py-xsm px-sm text-black leading-tight shadow-md`}
`;

const ContactFormContainer = styled.div`
  ${tw`
       flex flex-wrap md:border md:border-gray-800 mx-auto
    `}
`;

const ContactFormContent = styled.div`
  ${tw`w-full`}
`;

const ContactForm = ({
  onSuccess,
  label,
  form_title,
  form_description,
  template_version,
}) => {
  const [loading, setLoading] = useState(false);
  const i18NContext = useContext(I18NContext);
  const language =
    i18NContext && i18NContext.language && i18NContext.language === "english"
      ? "en"
      : "es";
  const contactSubmit = (formValue, callback) => {
    const url = document.getElementById("contactForm").getAttribute("action");
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const parsedForm = {
      nombre: formValue["First Name"],
      apellidos: formValue["Last Name"],
      movil: formValue["Phone"],
      ciudad: formValue["City"],
      intention: `Intención de compra : ${formValue["Intention"]}`,
    };

    trackLead();

    callback({
      loading: true,
    });
    axios
      .default({
        url: url,
        form: parsedForm,
        headers: options.headers,
      })
      .then((result) => {
        callback({
          loading: false,
        });
        if (onSuccess) {
          onSuccess();
        } else {
          navigate("/exito");
        }
      })
      .catch((error) => {
        console.error(error);
        callback({
          loading: false,
        });
      });
  };

  const trackLead = () => {
    if (typeof window !== `undefined`) {
      if (window.fbq) {
        window.fbq("track", "Lead", {});
      }

      if (window.dataLayer) {
        window.dataLayer.push({ event: "contact-form" });
      }
    }
  };

  const errorElement = (error) => <li>{error}</li>;

  const errorsList = (errors) => {
    const keys = Object.keys(errors);
    return <ul>{keys.map((errorKey) => errorElement(errors[errorKey]))}</ul>;
  };
  const formEl = useRef(null);

  const doHtmlPost = (values) => {
    setLoading(true);
    trackLead();
    setTimeout(function () {
      formEl.current.submit();
      setLoading(false);
    }, 3000);
  };

  const formI18n = {
    es: {
      firstName: "Nombre",
      lastName: "Apellido",
      email: "Email",
      phone: "Número de teléfono",
      city: "Ciudad",
      budget: "Presupuesto que desea invertir",
      intention: "Intención de compra",
      monthlyFamilyIncome: "Ingreso familiar mensual",
      numberOfBedrooms: "Cantidad de Recámaras deseadas",
      congrats:
        "¡Felicitaciones! Todos los datos se han completado correctamente",
    },
    en: {
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone Number",
      email: "Email",
      city: "City",
      budget: "Budget",
      intention: "Buying intention",
      monthlyFamilyIncome: "Monthly family income",
      numberOfBedrooms: "Number of bedrooms desired",
      congrats: "Congratulations! All data has been completed correctly",
    },
  };

  const getSchema = (language) => {
    const ContactFormSchemaEs = Yup.object().shape({
      "First Name": Yup.string().required("El Nombre es requerido"),
      "Last Name": Yup.string().required("El Apellido es requerido"),
      Email: Yup.string()
        .email("Ingrese un email válido")
        .required("El email es requerido"),
      Phone: Yup.string().required("El número de teléfono es requerido"),
    });

    const ContactFormSchemaEn = Yup.object().shape({
      "First Name": Yup.string().required("First name is required"),
      "Last Name": Yup.string().required("Last Name is required"),
      Email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
      Phone: Yup.string().required("Phone Number is required"),
    });

    return language === "es" ? ContactFormSchemaEs : ContactFormSchemaEn;
  };

  const getSuccessUrl = (language) =>
    language === "es"
      ? `https://www.arekatowers.com/exito`
      : `https://www.arekatowers.com/en/success`;

  const TextInputStyles = {
    v1: TextInputCSS,
    v2: TextInputCSSV2,
    v3: TextInputCSS,
    v4: TextInputCSSV2,
  };

  const SelectStyles = {
    v1: SelectInputCSS,
    v2: SelectInputCSSV2,
    v3: SelectInputCSS,
    v4: SelectInputCSSV2,
  };

  const TextInputStyleToApply = TextInputStyles[template_version];
  const SelectStylesToApply = SelectStyles[template_version];

  return (
    <ContactFormContainer
      css={css`
        ${tw`xl:w-2/4 lg:p-10 w-full px-lg py-base md:px-lg md:py-sm bg-white text-black`}
      `}
    >
      {form_title && form_title.html && (
        <div
          css={css`
            ${tw`w-full mx-auto`}
          `}
        >
          <div
            css={css`
              ${tw`text-4xl xl:text-center text-center py-base`}
              h1 {
                color: black;
              }
            `}
          >
            <Heading heading={form_title} />
          </div>
        </div>
      )}
      {form_description && form_description.html ? (
        <div
          css={css`
            ${tw`pb-6 pt-2`};
            p {
              ${tw`text-base text-black md:leading-loose leading-relaxed font-light`};
            }
          `}
        >
          <TextSection section_text={form_description} />
        </div>
      ) : (
        ""
      )}

      <ContactFormContent>
        <Formik
          validationSchema={getSchema(language)}
          initialValues={{ email: "", name: "", phone: "" }}
          onSubmit={(values) => doHtmlPost(values)}
        >
          {({ errors, touched }) => {
            const hasErrors = !!Object.keys(errors).length;
            const hasBeenTouched = !!Object.keys(touched).length;
            const isFormValid = !hasErrors && hasBeenTouched;
            const buttonAllowed = css`
              ${tw`
                        mx-auto shadow-md lg:mx-0 w-full hover:underline hover:bg-primary-dark bg-primary-normal text-white font-hairline my-base py-sm px-xsm uppercase
                    `}
            `;
            const buttonDisabled = css`
              ${tw`
                        mx-auto shadow-md lg:mx-0 w-full hover:underline hover:bg-gray-300 bg-gray-300 opacity-50 text-black font-hairline my-base py-sm px-xsm uppercase cursor-not-allowed
                    `}
            `;

            const classToApply = !isFormValid ? buttonDisabled : buttonAllowed;
            const labelInvalid =
              language === "es"
                ? "Completa todos los campos requeridos"
                : "Complete all the required fields before submitting the form";
            const labelValid =
              language === "es"
                ? "Enviar datos de contacto"
                : "Send contact data";
            const labelToDisplay = !isFormValid ? labelInvalid : labelValid;
            const redirectOnSuccess =
              language === "es" ? "exito" : "en/success";
            return (
              <Form>
                <form
                  id="contactForm"
                  method="post"
                  ref={formEl}
                  action={`/${redirectOnSuccess}`}
                  name="contact"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <input type="hidden" name="bot-field" />
                  {isFormValid ? (
                    <div
                      css={css`
                        ${tw`mx-auto bg-success-lighter text-green-900 p-sm py-xsm mb-base text-justify`}
                      `}
                    >
                      {formI18n[language]["congrats"]}
                    </div>
                  ) : (
                    ""
                  )}
                  <FieldContainer>
                    <FieldContainer>
                      <Field
                        type="text"
                        css={css`
                          ${TextInputStyleToApply}
                        `}
                        name="First Name"
                        placeholder={formI18n[language]["firstName"]}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <Field
                        type="text"
                        css={css`
                          ${TextInputStyleToApply}
                        `}
                        name="Last Name"
                        placeholder={formI18n[language]["lastName"]}
                      />
                    </FieldContainer>
                    <Field
                      type="email"
                      css={css`
                        ${TextInputStyleToApply}
                      `}
                      name="Email"
                      placeholder={formI18n[language]["email"]}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      type="text"
                      css={css`
                        ${TextInputStyleToApply}
                      `}
                      name="Phone"
                      placeholder={formI18n[language]["phone"]}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      type="text"
                      css={css`
                        ${TextInputStyleToApply}
                      `}
                      name="City"
                      placeholder={formI18n[language]["city"]}
                    />
                  </FieldContainer>
                  {template_version !== "v3" && template_version !== "v4" ? (
                    <React.Fragment>
                      <FieldContainer>
                        <Field
                          component="select"
                          css={css`
                            ${SelectStylesToApply}
                          `}
                          name="Intention"
                          placeholder={formI18n[language]["intention"]}
                        >
                          <option value="Indeterminado">
                            {formI18n[language]["intention"]}
                          </option>
                          <option value="Menos de 3 meses">
                            Menos de 3 meses
                          </option>
                          <option value="De 3 a 6 meses">de 3 a 6 meses</option>
                          <option value="De 6 a 12 meses">
                            de 6 a 12 meses
                          </option>
                          <option value="Más 12">Más de 12 meses</option>
                        </Field>
                      </FieldContainer>
                      <FieldContainer>
                        <Field
                          component="select"
                          css={css`
                            ${SelectStylesToApply}
                          `}
                          name="Budget"
                          placeholder={formI18n[language]["budget"]}
                        >
                          <option value="">
                            {formI18n[language]["budget"]}
                          </option>
                          <option value="80,000 - 130,000">
                            80,000 USD - 130,000 USD
                          </option>
                          <option value="130,000 - 180,000">
                            130,000 USD - 180,000 USD
                          </option>
                          <option value="180,000 - 230,000">
                            180,000 USD - 230,000 USD
                          </option>
                          <option value="230,000 - 280,000">
                            230,000 USD - 280,000 USD
                          </option>
                          <option value="280,000 - 320,000">
                            280,000 USD - 320,000 USD
                          </option>
                        </Field>
                      </FieldContainer>
                    </React.Fragment>
                  ) : null}
                  {template_version === "v4" ? (
                    <React.Fragment>
                      <FieldContainer>
                        <Field
                          component="select"
                          css={css`
                            ${SelectStylesToApply}
                          `}
                          name="monthlyFamilyIncome"
                          placeholder={
                            formI18n[language]["monthlyFamilyIncome"]
                          }
                        >
                          <option value="Indeterminado">
                            {formI18n[language]["monthlyFamilyIncome"]}
                          </option>
                          <option value="2,500 USD - 3,500 USD">
                            2,500 USD - 3,500 USD
                          </option>
                          <option value="Menos de 3 meses">
                            3,500 USD - 4,500 USD
                          </option>
                          <option value="+4,500 USD">+4,500 USD</option>
                        </Field>
                      </FieldContainer>
                      <FieldContainer>
                        <Field
                          component="select"
                          css={css`
                            ${SelectStylesToApply}
                          `}
                          name="numberOfBedrooms"
                          placeholder={formI18n[language]["numberOfBedrooms"]}
                        >
                          <option value="">
                            {formI18n[language]["numberOfBedrooms"]}
                          </option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="+4">+4</option>
                        </Field>
                      </FieldContainer>
                    </React.Fragment>
                  ) : null}
                  {hasErrors && hasBeenTouched ? (
                    <div
                      css={css`
                        ${tw`mx-auto bg-red-300 text-red-900 py-4 px-2 mt-4 mb-4 text-justify`}
                      `}
                    >
                      {errorsList(errors)}
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    disabled={!isFormValid || loading}
                    type="submit"
                    css={css(classToApply)}
                  >
                    {loading ? (
                      <img
                        css={css`
                          ${tw`w-12 h-3 my-1 mx-auto`}
                        `}
                        src="https://res.cloudinary.com/omochism/image/upload/v1610071824/Omochi/pde2yazo0mv7bnl6j5kh.svg"
                      />
                    ) : (
                      labelToDisplay
                    )}
                  </button>
                </form>
              </Form>
            );
          }}
        </Formik>
      </ContactFormContent>
    </ContactFormContainer>
  );
};

ContactForm.propTypes = {
  onSuccess: func,
  label: string,
};

ContactForm.defaultProps = {
  label: "Enviar datos de contacto",
};

export default ContactForm;
