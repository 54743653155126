import React, { useContext, useEffect } from "react"
import { graphql, StaticQuery } from "gatsby"
import * as R from "ramda"
import Layout from "../../components/Layout/Layout"
import SalesPageSlices from "./components/Slices"
import SEO from "../../../../omochi-components/src/components/SEO"
import ContactBar from "../../../../omochi-components/src/components/ContactBar"
import CloudinaryUtils from "../../../../omochi-components/src/components/CloudinaryUtils"
import { ImageGalleryContext } from "../../components/ImageGallery/Context"
import ImageGalleryModal from "../../../../omochi-components/src/components/ImageGallery/ImageGalleryModal"
import { I18NContext, I18NProvider, I18NConsumer } from "../../components/i18n/Context"

const getFirstBodyElement = body => body[0]

const getFirstBodyElementType = body =>
  R.pipe(getFirstBodyElement, R.path(["slice_type"]))(body)

const SalesPage = ({ data, location }) => {
  console.log(data)
  const i18nContext = useContext(I18NContext)
  const firstElementType = getFirstBodyElementType(
    data.prismicSalesPage.data.body,
  )
  const pageData = R.path(["prismicSalesPage", "data"], data)
  const pageTags = R.path(["prismicSalesPage", "tags"], data)
  console.log("pageTags: ", pageTags)
  const siteMetadata = R.path(["site", "siteMetadata"], data)
  const navBarCanBeTransparent =
    firstElementType === "banner_with_caption" ||
    firstElementType === "bannercarousel" ||
    firstElementType === "video_banner" ||
    firstElementType === "image_gallery"

  CloudinaryUtils.setCloudinaryUrl("forestapanama")
  const pageLanguage =
    pageTags.indexOf("english") !== -1 ? "english" : "spanish"
  // useEffect(() =>{
  //   i18nContext.setLanguage(pageLanguage)
  // }, [])

  const pageSEO = {
    title: pageData.title
      ? R.path(["title", "text"], pageData)
      : siteMetadata.defaultTitle,
    description: pageData.title
      ? R.path(["short_description", "text"], pageData)
      : siteMetadata.defaultDescription,
    titleTemplate: siteMetadata.titleTemplate,
    image: pageData.title
      ? R.path(["main_image", "url"], pageData)
      : siteMetadata.defaultImage,
  }
  const { showGalleryModal } = useContext(ImageGalleryContext)
  const contact_bar = {
    whatsapp_link: {
      link_type: "Web",
      url: "http://bit.ly/2wTrvW7",
      target: "",
    },
    contact_link: {
      html: "<p>/contacto</p>",
      text: "/contacto",
    },
    contact_label: {
      html: "<p>Contactar</p>",
      text: "Contactar",
    },
    phone_link: {
      link_type: "Web",
      url: "tel:+5076205-8718",
      target: "",
    },
    phone_label: {
      html: "<p>Llamar</p>",
      text: "Llamar",
    },
  }

  const contact_bar_english = {
    whatsapp_link: {
      link_type: "Web",
      url: "http://bit.ly/2wTrvW7",
      target: "",
    },
    contact_link: {
      html: "<p>/en/contact</p>",
      text: "/en/contact",
    },
    contact_label: {
      html: "<p>Contact Us</p>",
      text: "Contact Us",
    },
    phone_link: {
      link_type: "Web",
      url: "tel:+5076205-8718",
      target: "",
    },
    phone_label: {
      html: "<p>Call us</p>",
      text: "Call us",
    },
  }

  const contactBarToUse =
    pageLanguage === "spanish" ? contact_bar : contact_bar_english

  // const contact_bar = false;

  return <I18NProvider>
    <I18NConsumer>
      {value => {
        console.log("Value for consumer: ", value)
        return (
          <Layout
            navBarCanBeTransparent={navBarCanBeTransparent}
            isDistractionFree={false}
            hideFooter={false}
            location={location}
            i18nCons={value}
          >
            {showGalleryModal ? <ImageGalleryModal/> : <div/>}
            <SEO
              title={R.path(["title"], pageSEO)}
              description={R.path(["title"], pageSEO)}
              titleTemplate={R.path(["titleTemplate"], pageSEO)}
              image={R.path(["image"], pageSEO)}
              language={pageLanguage === "english" ? "en-us" : "es"}
            />
            <SalesPageSlices
              body={R.path(["prismicSalesPage", "data", "body"], data)}
            />
            {contact_bar ? <ContactBar {...contactBarToUse} /> : null}
          </Layout>
        )
      }}
    </I18NConsumer>
  </I18NProvider>
}

/* Query for Template */

export const query = graphql`
    query SalesPageQuery($pathSlug: String!) {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultImage: image
                defaultDescription: description
                siteUrl
            }
        }

        prismicSalesPage(uid: { eq: $pathSlug }) {
            uid
            tags
            data {
                title {
                    html
                    text
                }
                short_description {
                    html
                    text
                }
                main_image {
                    alt
                    copyright
                    url
                }

                body {
                    ...BannerWithCaptionFragment
                    ...ImageGalleryFragment
                    #          ...GeoLocationFragment
                    ...FormFragment
                    ...TextSectionFragment
                    ...FeaturesSectionFragment
                    ...RelatedPagesFragment
                    ...MatterportFragment
                }
            }
        }
    }
`

export default SalesPage


