import React from "react";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import styled from "@emotion/styled";

const StyledTextSection = styled.div`
  ${tw`leading-snug`}
  h1 {
        ${tw`text-purple-700 my-xsm text-purple-700 text-2xl font-normal font-sans`}
    }
    
    h2 {
        ${tw`text-purple-900 font-thin md:text-xl pb-sm font-normal`}
    }
    p {
        ${tw`text-2xl font-sans text-gray-700 font-light text-justify md:text-xl my-sm`}
    }
    
    strong {
        ${tw`text-primary-dark font-normal text-gray-900 font-sans`}
    }
`;

const StyledTextSectionContainer = styled.div`
    ${props => {
        switch(props.margin_x) {
            case("base"): return tw`mx-base`;
            case("xxsm"): return tw`mx-xxsm`;
            case("xsm"): return tw`mx-xsm`;
            case("sm"): return tw`mx-sm`;
            case("lg"): return tw`mx-lg`;
            case("xlg"): return tw`mx-xlg`;
            case("auto"): return tw`mx-auto`;
            case("0"): return tw`mx-0`;
            default: return tw`mx-base`
        }
    }}

    ${props => {
        switch(props.margin_y) {
            case("base"): return tw`my-base`;
            case("xxsm"): return tw`my-xxsm`;
            case("xsm"): return tw`my-xsm`;
            case("sm"): return tw`my-sm`;
            case("lg"): return tw`my-lg`;
            case("xlg"): return tw`my-xlg`;
            case("auto"): return tw`my-auto`;
            case("0"): return tw`my-0`;
            default: return tw`my-base`
        }
    }}
    
    ${props => {
    switch(props.margin_x_md) {
        case("base"): return tw`md:mx-base`;
        case("xxsm"): return tw`md:mx-xxsm`;
        case("xsm"): return tw`md:mx-xsm`;
        case("sm"): return tw`md:mx-sm`;
        case("lg"): return tw`md:mx-lg`;
        case("xlg"): return tw`md:mx-xlg`;
        case("auto"): return tw`md:mx-auto`;
        case("0"): return tw`md:mx-0`;
        default: return tw`md:mx-base`
    }
}}

    ${props => {
    switch(props.margin_y_md) {
        case("base"): return tw`md:my-base`;
        case("xxsm"): return tw`md:my-xxsm`;
        case("xsm"): return tw`md:my-xsm`;
        case("sm"): return tw`md:my-sm`;
        case("lg"): return tw`md:my-lg`;
        case("xlg"): return tw`md:my-xlg`;
        case("auto"): return tw`md:my-auto`;
        case("0"): return tw`md:my-0`;
        default: return tw`md:my-base`
    }
}}

${props => {
    switch(props.section_alignment) {
        case("center"): return tw`text-center`;
        case("right"): return tw`text-right`;
        case("left"): return tw`text-left`;
        default: return tw`text-justify`
    }
}}

${props => {
    switch(props.section_alignment) {
        case("center"): return tw`text-center`;
        case("right"): return tw`text-right`;
        case("left"): return tw`text-left`;
        default: return tw`text-justify`
    }
}}

${props => {
    switch(props.padding_x) {
        case("base"): return tw`px-base`;
        case("xxsm"): return tw`px-xxsm`;
        case("xsm"): return tw`px-xsm`;
        case("sm"): return tw`px-sm`;
        case("lg"): return tw`px-lg`;
        case("xlg"): return tw`px-xlg`;
        case("auto"): return tw`px-auto`;
        case("0"): return tw`px-0`;
        default: return tw`px-base`
    }
}}

${props => {
    switch(props.padding_x_md) {
        case("base"): return tw`md:px-base`;
        case("xxsm"): return tw`md:px-xxsm`;
        case("xsm"): return tw`md:px-xsm`;
        case("sm"): return tw`md:px-sm`;
        case("lg"): return tw`md:px-lg`;
        case("xlg"): return tw`md:px-xlg`;
        case("auto"): return tw`md:px-auto`;
        case("0"): return tw`md:px-0`;
        default: return tw`md:px-base`
    }
}}

${props => {
    switch(props.padding_y_md) {
        case("base"): return tw`md:py-base`;
        case("xxsm"): return tw`md:py-xxsm`;
        case("xsm"): return tw`md:py-xsm`;
        case("sm"): return tw`md:py-sm`;
        case("lg"): return tw`md:py-lg`;
        case("xlg"): return tw`md:py-xlg`;
        case("auto"): return tw`md:py-auto`;
        case("0"): return tw`md:py-0`;
        default: return tw`md:py-base`
    }
}}

${props => {
    switch(props.padding_y) {
        case("base"): return tw`py-base`;
        case("xxsm"): return tw`py-xxsm`;
        case("xsm"): return tw`py-xsm`;
        case("sm"): return tw`py-sm`;
        case("lg"): return tw`py-lg`;
        case("xlg"): return tw`py-xlg`;
        case("auto"): return tw`py-auto`;
        case("0"): return tw`py-0`;
        default: return tw`py-base`
    }
}}

${props => {
    switch(props.section_width_md)
    {
        case("w-1/2"):
            return tw`w-full md:w-1/2 mx-auto`;
        case("w-1/3"):
            return tw`w-full md:w-1/3 mx-auto`;
        case("w-1/4"):
            return tw`w-full md:w-1/4 mx-auto`;
        case("w-3/5"):
            return tw`w-full md:w-3/5 mx-auto`;
        case("w-4/5"):
            return tw`w-full md:w-4/5 mx-auto`;
        default:
            return tw`w-full mx-auto`
    }
}}`;

const TextSection = ({
                         section_text,
                         padding_x,
                         padding_y,
                         margin_x,
                         margin_y,
                         padding_x_md,
                         padding_y_md,
                         margin_x_md,
                         margin_y_md,
                         section_alignment,
                         section_alignment_md,
                         section_width_md,
                         text_align,
                     }) => {


    return (
        <div className="flex">
            <StyledTextSectionContainer
                margin_y={margin_y}
                margin_x={margin_x}
                padding_y={padding_y}
                padding_x={padding_x}
                padding_x_md={padding_x_md}
                padding_y_md={padding_y_md}
                margin_x_md={margin_x_md}
                margin_y_md={margin_y_md}
                section_width_md={section_width_md}
                section_alignment={section_alignment}
                text_align={text_align}
            >
                <StyledTextSection
                    dangerouslySetInnerHTML={{__html: section_text.html}}
                />
            </StyledTextSectionContainer>
        </div>

    );
};

export default TextSection;
