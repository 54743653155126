import React from 'react';
import tw from 'tailwind.macro';
import styled from "@emotion/styled";

const StyledHeading = styled.div`
  h1 {
        ${tw`text-gray-900 text-2xl md:text-3xl font-normal uppercase`}
    }
    
    h2 {
        ${tw`text-gray-900 text-xl md:text-2xl font-normal`}
    }
    img {
      ${tw`h-10 md:h-12 w-auto mx-auto`}
    }
`;

const Heading = ({ heading, size }) => (
    <StyledHeading dangerouslySetInnerHTML={{__html:heading.html}} />
);


export default Heading;
