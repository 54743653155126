import React, { useContext } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import RelatedPage from "./RelatedPage";
import RelatedPageV2 from "./RelatedPageV2";
import { I18NContext } from "../i18n/Context";

const GetComponentToRender = (
  page,
  template_model,
  number_of_pages,
  language,
) => {
  switch (template_model) {
    case "v1":
      return <RelatedPage page={page} language={language} />;
    case "v2":
      return (
        <RelatedPageV2
          number_of_pages={number_of_pages}
          page={page}
          language={language}
        />
      );
    default:
      return <RelatedPage page={page} language={language} />;
  }
};

const GridRelatedPagesContainer = styled.section`
  ${tw`flex flex-wrap`};
`;

const GridElementContainer = styled.div`
  ${(props) => {
    if (props.number_of_pages === 1) {
      return tw`w-full mx-auto`;
    }
    return tw`w-full md:w-1/2 mx-auto`;
  }};
  ${(props) => {
    switch (props.card_padding_x) {
      case "base":
        return tw`px-base`;
      case "xxsm":
        return tw`px-xxsm`;
      case "xsm":
        return tw`px-xsm`;
      case "sm":
        return tw`px-sm`;
      case "lg":
        return tw`px-lg`;
      case "xlg":
        return tw`px-xlg`;
      case "auto":
        return tw`px-auto`;
      case "0":
        return tw`px-0`;
      default:
        return tw`px-base`;
    }
  }}

  ${(props) => {
    switch (props.card_padding_y) {
      case "base":
        return tw`py-base`;
      case "xxsm":
        return tw`py-xxsm`;
      case "xsm":
        return tw`py-xsm`;
      case "sm":
        return tw`py-sm`;
      case "lg":
        return tw`py-lg`;
      case "xlg":
        return tw`py-xlg`;
      case "auto":
        return tw`py-auto`;
      case "0":
        return tw`py-0`;
      default:
        return tw`py-base`;
    }
  }}
`;

const RelatedPages = ({
  pages,
  template_model,
  page_group_display,
  max_elements_per_row,
  card_padding_x,
  card_padding_y,
}) => {
  const i18NContext = useContext(I18NContext);
  let language = "spanish";
  console.log("i18NContext: ", i18NContext);
  if (i18NContext && i18NContext.language) {
    language = i18NContext.language;
  }
  const numberOfPages = pages.length;
  const maxElementsPerRow = parseInt(max_elements_per_row || pages.length);

  if (page_group_display === "list" || page_group_display) {
    return (
      <GridRelatedPagesContainer>
        {pages.map((page) => (
          <GridElementContainer
            number_of_pages={maxElementsPerRow}
            card_padding_x={card_padding_x}
            card_padding_y={card_padding_y}
          >
            {GetComponentToRender(
              page,
              template_model,
              maxElementsPerRow,
              language,
            )}
          </GridElementContainer>
        ))}
      </GridRelatedPagesContainer>
    );
  } else {
    const paddingY = maxElementsPerRow === 1 ? "py-base" : null;
    return (
      <GridRelatedPagesContainer number_of_pages={maxElementsPerRow}>
        {pages.map((page) => {
          return (
            <GridElementContainer
              number_of_pages={maxElementsPerRow}
              card_padding_x={card_padding_x}
              card_padding_y={paddingY || card_padding_y}
            >
              {GetComponentToRender(
                page,
                template_model,
                maxElementsPerRow,
                language,
              )}
            </GridElementContainer>
          );
        })}
      </GridRelatedPagesContainer>
    );
  }
};

export default RelatedPages;
