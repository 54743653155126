import React from "react";
import FeaturesBullets from "./FeaturesBullets";

const FeaturesBulletsSection = ({features}) => {
    return (
        <div>
            <FeaturesBullets bullets={features}/>
        </div>
    )
};

export default FeaturesBulletsSection;
