import React from "react";
import ContactForm from "../ContactForm/ContactForm";
import tw from "tailwind.macro";
import styled from "@emotion/styled";
import BackgroundImage from "gatsby-background-image";
import { css } from "@emotion/core";

const StyledFormContainer = styled.div``;
const StyledFormWithBackground = styled.div``;
const StyledFormWithoutBackground = styled.div``;

const GetFormFluid = (imageFile) => {
  let formBackgroundSource = [];
  if (imageFile) {
    formBackgroundSource = [
      imageFile.mobileImage.fluid,
      imageFile.desktopImage.fluid,
      imageFile.desktop80QualityImage.fluid,
    ];
  }
  return formBackgroundSource;
};

const StyledContactFormContainer = styled.div`
  ${tw`
       pt-base bg-white text-white
    `}
`;
const StyledContactFormContainer2 = styled.div`
  ${tw`
        px-8 py-8 bg-white text-white
    `}
`;

const StyledContactFormContainerWrapper = styled.div`
  ${(props) => {
    switch (props.template_version) {
      case "v2":
        return `${tw`lg:w-full bg-blue-500 xl:p-8 md:p-4 md:p-8 w-full mx-auto`}`;
      default:
        return `${tw`lg:w-3/4 bg-red-500 md:p-4 md:p-8 w-full mx-auto`}`;
    }
  }}
`;

const grayscale = `
    -moz - filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");`;

const ContactFormContainer = ({
  form_title,
  form_description,
  form_background,
  style,
  template_version,
}) => {
  console.warn(
    form_title,
    form_description,
    form_background,
    style,
    template_version,
  );
  if (form_background) {
    const { imageFile } = form_background;
    const sources = GetFormFluid(imageFile);

    return (
      <StyledFormContainer>
        <StyledFormWithBackground>
          <StyledContactFormContainer>
            <BackgroundImage
              Tag={`div`}
              id={`form-background-image`}
              fluid={sources}
            >
              <StyledContactFormContainerWrapper
                template_version={template_version}
              >
                <ContactForm
                  template_version={template_version}
                  form_description={form_description}
                  form_title={form_title}
                />
              </StyledContactFormContainerWrapper>
            </BackgroundImage>
          </StyledContactFormContainer>
        </StyledFormWithBackground>
      </StyledFormContainer>
    );
  }

  return (
    <StyledFormContainer>
      <StyledFormWithBackground>
        <StyledContactFormContainer>
          <StyledContactFormContainerWrapper
            template_version={template_version}
          >
            <ContactForm
              template_version={template_version}
              form_description={form_description}
              form_title={form_title}
            />
          </StyledContactFormContainerWrapper>
        </StyledContactFormContainer>
      </StyledFormWithBackground>
    </StyledFormContainer>
  );
};

export default ContactFormContainer;
