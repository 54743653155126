import React, {useState, useEffect, useRef} from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Image from 'gatsby-image';
import * as R from "ramda";
import TextSection from "../../Text";
import Heading from "../../Heading/Heading";
import CloudinaryBackgroundImage from "../../CloudinaryBackgroundImage";

const StyledFeaturesCarouselContainer = styled.div`
  ${tw`flex w-full`}
`;


const StyledFeaturesCarouselElementContainer = styled.div`
  ${tw`w-full`}
`;

const StyledFeatureElementCardBody = styled.div`
${tw`p-sm`}
`
const FeatureElementDescription = styled.div`
  ${tw`w-full`}
`;

const FeatureImageContainer = styled.div`
  ${tw`w-full`}
`;

const FeatureImage = styled(Image)`
  min-height: 50vh;
  ${tw`rounded`}
  height: ${(props) => props.height || '100vh'};
  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > img {
    object-fit: ${(props) => props.fit || 'cover'} !important;
    object-position: ${(props) => props.position || '50% 50%'} !important;
    font-family: 'object-fit: ${(props) => props.fit || 'cover'} !important; object-position: ${(
    props,
) => props.position || '50% 50%'} !important;'
}
`;

const StyledCarouselElement = styled.div`
    ${(props) => {
        if (props.isInactive) {
            return tw`hidden md:block md:w-1/4 opacity-25`
        }
        return tw`w-full md:w-1/2 text-justify`
    }};
    
    ${props => {
        if (props.position === 'left') {
            return tw`pr-sm`
        }
        if (props.position === 'right') {
            return tw`pl-sm`
        }
    }}
`;

const CarouselElement = (props) => {
    return (
        <StyledCarouselElement
            {...props}
        >
            {props.children}
        </StyledCarouselElement>
    );
}

const FeaturesElement = ({featureElement, isInactive}) => {
    console.log('Features Carousel is here:', featureElement);
    const featureDescription = R.path(['feature_description'], featureElement);
    const featureTitle = R.path(['feature_title'], featureElement);
    const featureImageUrl = R.path(['feature_image', 'url'], featureElement);

    return (
        <StyledFeaturesCarouselElementContainer>
            {
                featureImageUrl && (
                    <FeatureImageContainer>
                        <CloudinaryBackgroundImage
                            url={featureImageUrl}
                            image_width={640}
                            image_height={480}
                            quality={80}
                            background_size={"cover"}
                            background_height={"45vh"}
                        />
                    </FeatureImageContainer>
                )
            }
            <StyledFeatureElementCardBody>
                {
                    featureTitle && !isInactive && (
                        <Heading heading={featureTitle}/>
                    )
                }
                {
                    featureDescription && !isInactive && (

                        <FeatureElementDescription>
                            <TextSection
                                padding_x={"0"}
                                padding_x_md={"0"}
                                padding_y_md={"0"}
                                margin_y_md={"0"}
                                margin_x={"0"}
                                margin_x_md={"0"}
                                section_text={featureDescription}/>
                        </FeatureElementDescription>
                    )
                }
            </StyledFeatureElementCardBody>
        </StyledFeaturesCarouselElementContainer>
    );
};

const CarouselControl = styled.div`
  ${tw`my-auto z-10`};
  & > button {
    ${tw`text-2xl md:text-5xl p-xsm md:px-sm`}
  }
`;

const FeaturesCarouselContainer = (props) => {
    const carouselContainerRef = useRef(null);
    return (
        <StyledFeaturesCarouselContainer
            ref={carouselContainerRef}
            {...props}
        >
            {props.children}
        </StyledFeaturesCarouselContainer>
    )
};


const FeaturesCarousel = ({features}) => {
    const [currentPosition, setCurrentPosition] = useState(0);
    const [centralElement, setCentralElement] = useState({});
    const [leftElement, setLeftElement] = useState({});
    const [rightElement, setRightElement] = useState({});
    console.log('FeaturesCarousel: ', features);
    const setElements = () => {
        setCentralElement(features[currentPosition]);
        if(currentPosition === 0) {
            setLeftElement(features[features.length - 1]);
            setRightElement(features[currentPosition + 1]);
        } else if (
            currentPosition === features.length -1
        ) {
            setRightElement(features[0]);
            setLeftElement(features[currentPosition - 1])
        } else {
            setLeftElement(features[currentPosition - 1]);
            setRightElement(features[currentPosition + 1]);
        }
    };


    useEffect(() => {
        setElements();
    }, []);


    useEffect(() => {
        setElements()
    }, [currentPosition]);

    const goPrev = () => currentPosition === 0 ? setCurrentPosition(features.length - 1) : setCurrentPosition(currentPosition - 1);
    const goNext = () => currentPosition === features.length -1 ? setCurrentPosition(0) : setCurrentPosition(currentPosition + 1);

    return (
        <React.Fragment>
            <FeaturesCarouselContainer

            >
                <CarouselControl>
                    <button
                        onClick={() => goPrev()}
                    >
                        &lt;
                    </button>
                </CarouselControl>
                <CarouselElement
                    isInactive={true}
                    onClick={() => goPrev()}
                    position={"left"}
                >
                    <FeaturesElement
                        isInactive={true}
                        featureElement={leftElement}/>
                </CarouselElement>
                <CarouselElement
                    isInactive={false}
                    position={"center"}
                >
                    <FeaturesElement
                        isInactive={false}
                        featureElement={centralElement}/>
                </CarouselElement>

                <CarouselElement
                    onClick={() => goNext()}
                    position={"right"}
                    isInactive={true}
                >
                    <FeaturesElement
                        isInactive={true}
                        featureElement={rightElement}/>
                </CarouselElement>
                <CarouselControl>
                    <button
                        onClick={() => goNext()}
                    >
                        &gt;
                    </button>
                </CarouselControl>
            </FeaturesCarouselContainer>
        </React.Fragment>
    );
};


export default FeaturesCarousel;

// swipeLeft={() => {
//     console.log('Trying to go prev');
//     console.log('Current position: ', currentPosition);
//
//     if(currentPosition === 0) {
//         setCurrentPosition(features.length - 1);
//     } else {
//         setCurrentPosition(currentPosition - 1)
//     }
// }}
// swipeRight={() => {
//     console.log('Trying to go next: ');
//     console.log('Current position: ', currentPosition);
//     if(currentPosition === features.length -1) {
//         setCurrentPosition(0);
//     } else {
//         setCurrentPosition(currentPosition + 1);
//     }
// }}
