import React from "react";
import { graphql } from "gatsby";
import { number } from "prop-types";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker/Marker";
import TextSection from "../Text/index";
import Heading from "../Heading/Heading";
import { css } from "@emotion/core";

const GeoLocationContainer = styled.div`
  ${tw`flex flex-wrap w-full md:w-4/5 mx-auto py-base px-base mb-sm md:my-sm`}
`;

const GeoLocationMapContainer = styled.div`
  ${tw`w-full px-0 mb-base rounded border border-solid border-gray-200 shadow`}
  height: 50vh;
  width: "100%";
`;

const GeoLocationTextContainer = styled.div`
  ${tw`flex flex-wrap p-0 my-auto`}
`;

const GeoLocationDescription = styled.div`
  ${tw`flex flex-wrap text-justify md:px-sm h-full`}
`;

const GeolocationTitleContainer = styled.div`
  ${tw`md:px-sm`}
`;
const GeoLocationGmapsLink = styled.a`
  ${tw`px-base bg-white border border-black text-black py-sm rounded-full`}
`;

const GeoLocationGmapsLinkContainer = styled.div`
  ${tw`w-full mt-base text-center`}
`;

const Geolocation = ({
  latitude,
  longitude,
  latitude2,
  longitude2,
  locationDescription,
  locationTitle,
  googleMapsLink,
}) => {
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
    });
    return marker;
  };

  return (
    <GeoLocationContainer>
      <GeoLocationTextContainer>
        <GeolocationTitleContainer>
          <Heading heading={locationTitle} />
        </GeolocationTitleContainer>
        {locationDescription ? (
          <GeoLocationDescription>
            <TextSection
              padding_x={"0"}
              padding_x_md={"0"}
              margin_y={"0"}
              margin_y_md={"0"}
              margin_x_md={"0"}
              margin_x={"0"}
              section_text={locationDescription}
            />
          </GeoLocationDescription>
        ) : (
          ""
        )}
      </GeoLocationTextContainer>
      <GeoLocationMapContainer>
        <GoogleMapReact
          options={{
            scrollwheel: false,
            zoomControl: false,
            disableDoubleClickZoom: true,
          }}
          bootstrapURLKeys={{ key: "AIzaSyB47Ab_jl8XNSqEPxo0-qmvqjeolYd1PUE" }}
          defaultCenter={{
            lat: latitude,
            lng: longitude,
          }}
          defaultZoom={13}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        />
        {googleMapsLink && googleMapsLink.url ? (
          <GeoLocationGmapsLinkContainer>
            <GeoLocationGmapsLink href={googleMapsLink.url} target={"_blank"}>
              Ver en Google Maps
            </GeoLocationGmapsLink>
          </GeoLocationGmapsLinkContainer>
        ) : (
          ""
        )}
      </GeoLocationMapContainer>
    </GeoLocationContainer>
  );
};

Geolocation.propTypes = {
  latitude: number.isRequired,
  longitude: number.isRequired,
};

export const GeoLocationFragment = graphql`
  fragment GeoLocation on PrismicSalesPageBodyLocation {
    slice_type
    primary {
      location_title {
        text
        html
      }
      location_description {
        text
        html
      }
      geopoint {
        latitude
        longitude
      }
    }
  }
`;

export default Geolocation;
