import React from "react";
import * as R from "ramda";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import TextSection from "../../Text";
import styled from "@emotion/styled";
import Image from "gatsby-image";

const RelatedPageOverlay = styled.div`
  ${tw`h-full w-full z-0`}
`;

const RelatedPageContentWrapper = styled.div`
  ${tw`h-full w-full absolute`}
`;

const RelatedPageContent = styled.div`
  ${tw`flex flex-col 
  text-white justify-end px-base py-xsm text-center absolute h-auto w-full bottom-0
  xl:justify-start xl:relative xl:w-1/4 xl:p-sm xl:text-left xl:h-full 
  `}
  background: rgba(0,0,0,.45);
`;

const RelatedPageTextContainer = styled.div`
  ${tw`flex flex-wrap my-auto`}
`;

const RelatedPageActionsContainer = styled.div`
  ${tw`w-full`}
`;

const RelatedPageContentTitle = styled.h1`
  ${tw`md:text-4xl text-3xl text-center py-sm md:py-base`}
`;

const RelatedPageContentShortDescription = styled.div`
  ${tw`md:text-2xl text-xl text-justify text-center mx-auto md:text-left md:py-base`}
`;

const RelatedPageMore = styled.button`
  ${tw`bg-white hover:bg-white 
  w-full text-primary-normal hover:text-black md:py-sm md:px-base border-2 border-white 
  hover:border-transparent xl:block text-xs uppercase rounded-full py-xsm mt-xsm`}
`;

const GetFluidMainImage = (pageDocument) => {
  const imageFile = R.path(["main_image", "imageFile"], pageDocument);

  const source = [
    imageFile.mobileImage.fluid,
    imageFile.desktopImage.fluid,
    {
      ...imageFile.desktop80QualityImage.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return source;
};

const RelatedPageIconContainer = styled.div`
  ${tw`w-full md:text-center`}
`;

const RelatedPage = ({ page, language }) => {
  console.log("Got language: ", language);
  const pageData = R.pick(
    ["uid", "id", "tags"],
    R.path(["related_page"], page),
  );
  const pageDocument = R.path(
    ["data"],
    R.path(["related_page", "document"], page)[0],
  );

  const navigationLink =
    language === "english" ? `/en/${pageData.uid}` : `${pageData.uid}`;
  const navigationLabel = language === "english" ? `See more` : "Conoce más";

  const getFluidAlternativeIcon = (alternativeIcon) => {
    const imageFile = alternativeIcon.imageFile;
    let source = [];
    if (imageFile) {
      source = [
        imageFile.mobileImage && imageFile.mobileImage.fluid
          ? imageFile.mobileImage.fluid
          : "",
        imageFile.desktopImage && imageFile.desktopImage.fluid
          ? imageFile.desktopImage.fluid
          : "",
      ];
    }

    return source;
  };

  return (
    <Link to={navigationLink}>
      <div>
        <BackgroundImage
          css={css`
            ${tw`h-screen w-full overflow-hidden`}
          `}
          Tag="div"
          loading={"eager"}
          fadeIn={false}
          fluid={GetFluidMainImage(pageDocument)}
        >
          <RelatedPageContentWrapper>
            <RelatedPageContent>
              <RelatedPageTextContainer>
                <RelatedPageIconContainer>
                  <BackgroundImage
                    tag="div"
                    loading={"eager"}
                    fadeIn={false}
                    fluid={getFluidAlternativeIcon(
                      pageDocument.alternative_icon,
                    )}
                    css={css`
                      background-repeat: no-repeat;
                      background-position: bottom center;
                      background-size: contain;
                      ${tw`w-auto h-20 md:h-40 mb-sm mx-auto`}
                    `}
                  />
                  <RelatedPageContentTitle>
                    {pageDocument.title.text}
                  </RelatedPageContentTitle>
                </RelatedPageIconContainer>
                <RelatedPageContentShortDescription
                  dangerouslySetInnerHTML={{
                    __html: pageDocument.short_description.html,
                  }}
                />
                <RelatedPageActionsContainer>
                  <RelatedPageMore>{navigationLabel}</RelatedPageMore>
                </RelatedPageActionsContainer>
              </RelatedPageTextContainer>
            </RelatedPageContent>
          </RelatedPageContentWrapper>
        </BackgroundImage>
      </div>
    </Link>
  );
};

export default RelatedPage;
