import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const BulletsSectionContainer = styled.div`
  ${tw`w-full`}
`;

const BulletsSectionContentContainer = styled.div`
  ${tw`w-full md:w-4/5 mx-auto`}
`;

const BulletsContainer = styled.div`
  ${tw`w-full mx-auto`}
`;

const FeaturesBullets = ({ bullets }) => {
    return (
        <BulletsSectionContainer>
            <BulletsSectionContentContainer>
                <BulletsContainer>
                    <StyledUl>
                        {bullets.map((bulletElement, index) => (
                            <StyledLi key={"StyledLi" + index}
                                      dangerouslySetInnerHTML={{__html: bulletElement.feature_title.html}}
                            >
                            </StyledLi>
                        ))}
                    </StyledUl>
                </BulletsContainer>
            </BulletsSectionContentContainer>
        </BulletsSectionContainer>
    );
};

const StyledUl = styled.ul`
  ${tw`flex flex-wrap my-base text-left`}
`;

const StyledLi = styled.li`
  ${tw`flex flex-inline text-justify items-center md:w-1/2 lg:w-1/3 w-full pr-sm py-sm my-auto`};
  &:before {
    content: "✔️";
    ${tw`mr-xsm`}
  }
`;

export default FeaturesBullets;
