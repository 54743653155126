import React, { useContext } from "react"
import * as R from "ramda"
import "./slices.css"

import { BannerWithCaptionFragment } from "../fragments/BannerWithCaption"
import { ImageGalleryFragment } from "../fragments/ImageGallery"
import { ImageGalleryContext } from "../../../../../omochi-components/src/components/ImageGallery/Context"
import ImageGallery from "../../../../../omochi-components/src/components/ImageGallery/ImageGallery"
import BannerWithCaption from "../../../../../omochi-components/src/components/BannerWithCaption/BannerWithCaption"
import { GeoLocationFragment } from "../fragments/GeoLocation"
import Geolocation from "../../../../../omochi-components/src/components/GeoLocation/GeoLocation"
import HighlightSection from "../../../../../omochi-components/src/components/HighlightSection/HighlightSection"
import BulletsSection from "../../../../../omochi-components/src/components/BulletsSection/BulletsSection"
import VideoBannerWithCaption from "../../../../../omochi-components/src/components/VideoBannerWithCaption"
import { FormFragment } from "../fragments/FormFragment"
import { FeaturesSectionFragment } from "../fragments/FeaturesSectionFragment"
import { RelatedPagesFragment } from "../fragments/RelatedPages"
import { VideoBannerFragment } from "../fragments/VideoBanner"
import ContactFormContainer from "../../../../../omochi-components/src/components/ContactFormContainer"
import Features from "../../../../../omochi-components/src/components/Features"
import Matterport from "../../../../../omochi-components/src/components/Matterport"
import RelatedPages from "../../../../../omochi-components/src/components/RelatedPages"
import TextSection from "./Text"

export const bannerWithCaptionFragment = BannerWithCaptionFragment
export const formFragment = FormFragment
export const imageGalleryFragment = ImageGalleryFragment
export const geolocationFragment = GeoLocationFragment
export const featuresSectionFragment = FeaturesSectionFragment
export const relatedPagesFragment = RelatedPagesFragment
export const videoBannerFragment = VideoBannerFragment

const getSliceRelatedDocumentPrimaryAndItems = (pathString, pickOptions) =>
  R.pipe(
    R.path([`${pathString}`, "document"]),
    result => result[0],
    R.path(["data", "body"])
  )

const getFeaturesItems = R.pipe(data => data[0], R.path(["items"]))

const sliceTypes = {
  text: "text",
  banner_with_caption: "banner_with_caption",
  image_gallery: "image_gallery",
  form: "form",
  location: "location",
  highlight_section: "highlight_section",
  product_bullets_section: "product_bullets_section",
  sectionsnavigation: "sectionsnavigation",
  relatedpages: "relatedpages",
  matterport: "matterport",
  timeline: "timeline",
  features: "features",
  video_banner: "video_banner",
}

const getSliceType = R.path(["slice_type"])
const getSliceId = R.path(["id"])

const SalesPageSlices = ({ body }) => {
  const imageGalleryContext = useContext(ImageGalleryContext)
  return (
    <React.Fragment>
      {body.map((bodyElement, bodyIndex) => {
        const sliceType = getSliceType(bodyElement)
        const sliceId = getSliceId(bodyElement)
        const slicePrimary = R.path(["primary"], bodyElement)
        const sliceItems = R.path(["items"], bodyElement)
        switch (sliceType) {
          case sliceTypes.text: {
            console.log("Text Slice Primary: ", slicePrimary);
            console.log("Text Slice Primary: ", bodyElement);
            return (
              <section
                id={sliceId}
                key={sliceId}
                tag={slicePrimary.section_id || ""}
              >
                <TextSection
                  key={sliceId}
                  padding_x={"px-base"}
                  padding_y={"sm"}
                  margin_x={"auto"}
                  margin_y={"0"}
                  margin_x_md={"auto"}
                  section_alignment={R.path(["section_alignment"], slicePrimary)}
                  section_alignment_md={R.path(["section_alignment"], slicePrimary)}
                  section_width_md={"w-1/2"}
                  section_text={R.path(["section_text"], slicePrimary)}
                />
              </section>
            )
          }

          case sliceTypes.banner_with_caption: {
            return (
              <section id={sliceId} key={sliceId}>
                <BannerWithCaption
                  key={sliceId}
                  padding={"lg"}
                  margin={"0"}
                  image_banner={R.path(["image_banner"], slicePrimary)}
                  image_banner_mobile={R.path(
                    ["image_banner_mobile"],
                    slicePrimary
                  )}
                  image_banner_title={R.path(
                    ["image_banner_title"],
                    slicePrimary
                  )}
                  image_banner_description={R.path(
                    ["image_banner_description"],
                    slicePrimary
                  )}
                  allow_zoom={R.path(["allow_zoom"], slicePrimary)}
                  template={R.path(["template"], slicePrimary)}
                  iconOverlay={R.path(["icon_overlay"], slicePrimary)}
                  background_repeat={R.path(
                    ["background_repeat"],
                    slicePrimary
                  )}
                  banner_image_size={R.path(
                    ["banner_image_size"],
                    slicePrimary
                  )}
                  useCloudinary={true}
                  background_height={R.path(
                    ["background_height"],
                    slicePrimary
                  )}
                  image_banner_call_to_action={R.path(
                    ["image_banner_call_to_action"],
                    slicePrimary
                  )}
                  socialLinks={[
                    {
                      id: "instagram",
                      link: "https://www.instagram.com/foresta.panama/",
                      icon:
                        "https://images.prismic.io/forestapanama/48f8e936-2f9f-44bb-ae55-0114cf61f73d_INSTAGRAM+COLOR+BLANCO+BANNER+INICIO.svg?auto=compress,format",
                    },
                    {
                      id: "facebook",
                      link: "https://www.facebook.com/forestapanama/",
                      icon:
                        "https://images.prismic.io/forestapanama/6f026d9e-d36e-43ef-bed5-fd068fa44a1e_FACEBOOK+COLOR+BLANCO+BANNER+INICIO.svg?auto=compress,format",
                    },
                    {
                      id: "vimeo",
                      link: "https://vimeo.com/398270882",
                      icon:
                        "https://images.prismic.io/forestapanama/8b6476ad-de75-4ba2-8eda-0ea403137076_VIMEO+COLOR+BLANCO+BANNER+INICIO.svg?auto=compress,format",
                    },
                  ]}
                />
              </section>
            )
          }
          case sliceTypes.highlight_section: {
            return (
              <section id={sliceId} key={sliceId}>
                <HighlightSection
                  key={sliceId}
                  highlight_description={R.path(
                    ["highlight_description"],
                    slicePrimary
                  )}
                  highlight_photo={R.path(["highlight_photo"], slicePrimary)}
                  highlight_title={R.path(["highlight_title"], slicePrimary)}
                />
              </section>
            )
          }

          case sliceTypes.matterport: {
            return (
              <div>
                <Matterport
                  title={"Conoce nuestro piso muestra"}
                  title_background={"primary-normal"}
                  matterport_id={R.path(
                    ["matterport_id", "text"],
                    slicePrimary
                  )}
                />
              </div>
            )
          }

          case sliceTypes.product_bullets_section: {
            return (
              <section id={sliceId} key={sliceId}>
                <BulletsSection
                  key={sliceId}
                  bullets_title={R.path(["bullets_title"], slicePrimary)}
                  bullets_description={R.path(
                    ["bullets_description"],
                    slicePrimary
                  )}
                  bullets={sliceItems}
                />
              </section>
            )
          }

          case sliceTypes.location: {
            return (
              <section id={sliceId}>
                <Geolocation
                  key={sliceId}
                  longitude={slicePrimary.geopoint.longitude}
                  latitude={slicePrimary.geopoint.latitude}
                  googleMapsLink={R.path(["google_maps_link"], slicePrimary)}
                  locationDescription={slicePrimary.location_description}
                  locationTitle={slicePrimary.location_title}
                />
              </section>
            )
          }
          case sliceTypes.video_banner: {
            return (
              <VideoBannerWithCaption
                videoUrl={R.path(["video_link", "url"], slicePrimary)}
              />
            )
          }
          case sliceTypes.form: {
            return (
              <section id={sliceId} className="mt-lg">
                <ContactFormContainer
                  key={sliceId}
                  template_version={"v4"}
                  form_title={R.path(["form_title"], slicePrimary)}
                  form_description={R.path(["form_description"], slicePrimary)}
                  style="margin-top: 5rem"
                />
              </section>
            )
          }
          case sliceTypes.image_gallery: {
            if (imageGalleryContext && imageGalleryContext.setGalleryItems) {
              imageGalleryContext.setGalleryItems(sliceItems)
              return (
                <section id={sliceId} key={sliceId}>
                  <ImageGallery key={sliceId} version={slicePrimary.version} />
                </section>
              )
            } else {
              return (
                <section id={sliceId} key={sliceId}>
                  {/* <ImageGallery key={sliceId} version={slicePrimary.version} /> */}
                </section>
              )
            }
          }
          case sliceTypes.relatedpages: {
            const pages = R.path(
              ["data", "related_pages"],
              R.path(["related_pages", "document"], slicePrimary)[0]
            )
            const groupData = R.pick(
              [
                "page_group_display",
                "page_group_title",
                "page_group_description",
                "template_model",
              ],
              slicePrimary
            )
            return (
              <div>
                <RelatedPages
                  template_model={R.path(["template_model"], groupData)}
                  pages={pages}
                  page_group_display={R.path(["page_group_display"], groupData)}
                />
              </div>
            )
          }

          case sliceTypes.features: {
            const features = getSliceRelatedDocumentPrimaryAndItems(
              "related_features"
            )(slicePrimary)
            return (
              <div>
                <Features
                  features_title={R.path(
                    ["features_section_title"],
                    slicePrimary
                  )}
                  section_background_color={R.path(
                    ["section_background_color"],
                    slicePrimary
                  )}
                  section_color_tone={R.path(
                    ["section_color_tone"],
                    slicePrimary
                  )}
                  features={getFeaturesItems(features)}
                  template={R.path(["template"], slicePrimary) || "v1"}
                />
              </div>
            )
          }
          default:
            return <div />
        }
      })}
    </React.Fragment>
  )
}

export default SalesPageSlices
