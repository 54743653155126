import React from "react";
import tw from "tailwind.macro";
import styled from "@emotion/styled";
import Heading from "../Heading/Heading";
import TextSection from "../Text";
import { library , icon } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
library.add(faCheckCircle);

const StyledUl = styled.ul`
  ${tw`flex flex-wrap my-xsm text-left mx-auto`}
`;

const StyledLi = styled.li`
  ${tw`flex inline-flex text-justify  text-gray-700 items-center md:w-1/2 lg:w-1/3 w-full pr-sm py-xsm`}
`;

const BulletsSectionContainer = styled.div`
  ${tw`w-full mb-sm`}
`;

const BulletsSectionContentContainer = styled.div`
  ${tw`w-full md:w-4/5 mx-auto px-lg md:px-xlg`}
`;

const BulletsTextSectionContainer = styled.div`
  ${tw`w-full`}
`;

const BulletsContainer = styled.div`
  ${tw`w-full mx-auto`}
`;

const BulletsDescriptionContainer = styled.div`
  ${tw`w-full text-justify py-xsm`}
`;

const CheckIcon = styled.div`
  ${tw`px-xsm text-green-600 text-lg`}
`

const BulletsSection = ({ bullets, bullets_title, bullets_description }) => {
  console.log(bullets);
  const checkIcon = icon({prefix: "fas", iconName: "check-circle"});
  console.log("checkIcon: ", checkIcon);
  return (
    <BulletsSectionContainer>
      <BulletsSectionContentContainer>
        <BulletsTextSectionContainer>
          <Heading heading={bullets_title} />
        </BulletsTextSectionContainer>
        <BulletsDescriptionContainer>
          <TextSection
              margin_x={"0"} padding_x={"0"}
              margin_x_md={"0"} padding_x_md={"0"}
              padding_y_md={"0"} padding_y={"0"}
              margin_y={"0"}
              section_text={bullets_description} />
        </BulletsDescriptionContainer>
        <BulletsContainer>
          <StyledUl>
            {bullets.map((bulletElement, index) => (
              <StyledLi key={"StyledLi" + index}

              >
                <CheckIcon dangerouslySetInnerHTML={{__html: checkIcon.html }}/>
                <span dangerouslySetInnerHTML={{__html: bulletElement.bullet_title.html}}>

                </span>
              </StyledLi>
            ))}
          </StyledUl>
        </BulletsContainer>
      </BulletsSectionContentContainer>
    </BulletsSectionContainer>
  );
};



export default BulletsSection;
