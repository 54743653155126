import React, { useContext, useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import { ImageGalleryContext } from "./Context";
import CloudinaryBackgroundImage from "../CloudinaryBackgroundImage";
import Lightbox from "react-image-lightbox";

const ImageGalleryContainer = tw.div`
  flex flex-wrap bg-black
`;

const MainImageContainer = tw.div`
  w-full md:w-1/2 border-l border-r border-b border-t border-black;
`;

const DesktopGridContainer = styled.div`
  ${tw`hidden md:flex md:flex-wrap md:w-1/2`};
  width: 50%;
`;

const GridElementContainer = styled.div`
  ${tw`w-1/2`}
`;

const ImageGalleryCallToActionContainer = styled.div`
  ${tw`w-full md:w-1/2 mx-auto text-center`};
`;

const ImageGalleryButton = styled.button`
  ${tw`absolute bg-white text-black py-2 px-4 border-2 border-white block text-xs uppercase rounded-full shadow`}
  bottom: 1.5rem;
  right: 1.5rem;
`;

const transitionImageGalleryElement = css`
  transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
    -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
    transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
    opacity 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;

  &:hover {
    opacity: 1 !important;
    transform: scale(1.05) !important;
    transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s !important;
  }
`;

const ImageGalleryElementContainer = styled.div`
  ${transitionImageGalleryElement};
  width: 100%;
`;

const ImageGalleryContainerv2 = tw.div`
  flex flex-col
`;

const GalleryImgContainerv2 = tw.div`
  lg:max-w-5xl md:max-w-4xl xl:max-w-6xl max-w-lg mx-auto mt-10 cursor-pointer
`;

const GalleryImgv2 = tw.img`
  relative w-full object-center object-cover
`;

const GalleryTitleV2 = tw.div`
  mt-3 text-lg
`;

const ImageGalleryContainerV3Style = css`
  @media only screen and (min-width: 1200px) {
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 2rem 0 2rem 0;
  }
`;

const ImageGalleryContainerV3 = styled.div`
  ${ImageGalleryContainerV3Style};
`;

const GalleryImgContainerV3 = tw.a`
  block w-full xl:mt-0 mt-5
`;

const ImageGalleryElement = ({ image }) => {
  return (
    <ImageGalleryElementContainer>
      <CloudinaryBackgroundImage
        image_width={800}
        image_height={800}
        quaility={80}
        background_height={"40vh"}
        background_size={"cover"}
        url={image.url}
      />
    </ImageGalleryElementContainer>
  );
};

const GetElementClassToApply = (currentElement, focusedElement) => {
  let cssToApply;
  if (currentElement === focusedElement) {
    cssToApply = css`
      ${tw`bg-black border-black overflow-hidden`}
    `;
  } else if (focusedElement && currentElement !== focusedElement) {
    cssToApply = css`
      ${tw`bg-black border-black overflow-hidden`}
      & div {
        opacity: 0.7 !important;
      }
    `;
  } else {
    cssToApply = css`
      ${tw`bg-black border-r border-b border-black overflow-hidden`}
    `;
  }
  return cssToApply;
};

const ImageGallery = ({ main_image_size, version }) => {
  const {
    galleryItems,
    galleryImages,
    loadGallery,
    showModal,
    setFocusedImage,
    focusedImage,
  } = useContext(ImageGalleryContext);
  const { mainImage, desktopGrid } = galleryImages;
  const [showGalleryLightbox, setShowGallery] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  let allImages;
  if (mainImage) {
    allImages = [mainImage].concat(desktopGrid);
  }
  if (!version || version === "v1") {
    return (
      <ImageGalleryContainer
        css={css`
          ${tw`relative w-full h-full overflow-hidden`}
          min-height: 80vh;
        `}
      >
        {loadGallery ? (
          <>
            <MainImageContainer
              onMouseEnter={() =>
                setFocusedImage(mainImage ? mainImage.url : null)
              }
              onMouseLeave={() => setFocusedImage(null)}
              onClick={() => {
                setPhotoIndex(0);
                setShowGallery(true);
              }}
              css={css`
                ${GetElementClassToApply(mainImage.url, focusedImage)}
                ${tw`overflow-hidden`}
              min-height:80vh;
                ${transitionImageGalleryElement}
                &:hover {
                  ${tw`cursor-pointer`}
                }
              `}
            >
              <CloudinaryBackgroundImage
                css={css`
                  ${tw`overflow-hidden`}
                `}
                background_height={"80vh"}
                quaility={80}
                image_width={800}
                image_height={600}
                background_size={main_image_size || "cover"}
                url={mainImage.url}
              />
            </MainImageContainer>
            <DesktopGridContainer>
              {desktopGrid.map((image, index) => {
                if (index < 4) {
                  return (
                    <GridElementContainer
                      onMouseEnter={() => setFocusedImage(image.url)}
                      onMouseLeave={() => setFocusedImage(null)}
                      onClick={() => {
                        setPhotoIndex(index);
                        setShowGallery(true);
                      }}
                      key={"GridElementContainer" + index}
                      css={css`
                        ${GetElementClassToApply(image.url, focusedImage)}
                        &:hover {
                          ${tw`cursor-pointer`}
                        }
                      `}
                    >
                      <ImageGalleryElement image={image} />
                    </GridElementContainer>
                  );
                }
              })}
            </DesktopGridContainer>

            <ImageGalleryButton onClick={() => setShowGallery(true)}>
              Ver galería
            </ImageGalleryButton>
          </>
        ) : null}

        {showGalleryLightbox && (
          <Lightbox
            mainSrc={allImages[photoIndex].url}
            nextSrc={allImages[(photoIndex + 1) % allImages.length].url}
            prevSrc={
              allImages[(photoIndex + allImages.length - 1) % allImages.length]
                .url
            }
            onCloseRequest={() => setShowGallery(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + allImages.length - 1) % allImages.length,
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % allImages.length)
            }
          />
        )}
      </ImageGalleryContainer>
    );
  } else if (version === "v2") {
    return (
      <ImageGalleryContainerv2>
        {allImages &&
          allImages.map((image, index) => {
            return (
              <GalleryImgContainerv2
                onClick={() => {
                  setPhotoIndex(index);
                  setShowGallery(true);
                }}
              >
                <GalleryImgv2
                  src={image.url}
                  className="relative w-full object-center object-cover"
                />
                <GalleryTitleV2>
                  {galleryItems &&
                  galleryItems[index] &&
                  galleryItems[index].image_title
                    ? galleryItems[index].image_title.text
                    : null}
                </GalleryTitleV2>
              </GalleryImgContainerv2>
            );
          })}

        {showGalleryLightbox && (
          <Lightbox
            mainSrc={allImages[photoIndex].url}
            nextSrc={allImages[(photoIndex + 1) % allImages.length].url}
            prevSrc={
              allImages[(photoIndex + allImages.length - 1) % allImages.length]
                .url
            }
            onCloseRequest={() => setShowGallery(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + allImages.length - 1) % allImages.length,
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % allImages.length)
            }
          />
        )}
      </ImageGalleryContainerv2>
    );
  } else if (version === "v3") {
    return (
      <ImageGalleryContainerV3>
        {allImages &&
          allImages.map((image, index) => {
            return (
              <GalleryImgContainerV3 href={galleryItems[index]?.link}>
                <CloudinaryBackgroundImage
                  css={css`
                    ${tw`overflow-hidden`}
                  `}
                  background_height={"450px"}
                  background_height_mobile={"180px"}
                  quaility={40}
                  background_size={main_image_size || "cover"}
                  url={image.url}
                />
              </GalleryImgContainerV3>
            );
          })}
      </ImageGalleryContainerV3>
    );
  }
};

export default ImageGallery;
