import React, { useEffect, useState } from "react";
import { node } from "prop-types";
import { GetGalleryGrid, GetParsedGalleryImages } from "./ImageGalleryUtils";

const ImageGalleryContext = React.createContext({});
const { Provider, Consumer } = ImageGalleryContext;
const ImageGalleryConsumer = Consumer;

const ImageGalleryProvider = ({ children }) => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [galleryImages, setGalleryImages] = useState({});
  const [loadGallery, setLoadGallery] = useState(false);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [focusedImage, setFocusedImage] = useState(null);

  const hideModal = () => {
    setShowGalleryModal(false);
  };
  const showModal = () => {
    setShowGalleryModal(true);
  };
  const setAndParseGalleryImages = (items, path) => {
    const parsedGallery = GetParsedGalleryImages(items, path);
    if (parsedGallery) {
      const gallery = GetGalleryGrid(parsedGallery);
      setGalleryImages(gallery);
      setLoadGallery(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoadGallery(false);
      setAndParseGalleryImages(galleryItems, ["gallery_image", "imageFile"]);
    }, 100);
  }, [galleryItems]);

  return (
    <Provider
      value={{
        galleryImages,
        loadGallery,
        setGalleryItems,
        setShowGalleryModal,
        hideModal,
        showModal,
        showGalleryModal,
        focusedImage,
        setFocusedImage,
      }}
    >
      {children}
    </Provider>
  );
};

ImageGalleryProvider.propTypes = {
  children: node.isRequired,
};

export { ImageGalleryConsumer,ImageGalleryContext, ImageGalleryProvider };
