import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import {Link} from "gatsby";

const StyledBannerCTA = styled.a`
  ${tw`w-full bg-white rounded`}
`;

const StyledBannerLink = styled.a`
  ${props => {
    if (props.primary_cta) {
            switch (props.call_to_action_style) {
                case("success"):
                    return tw`bg-success-dark rounded-full text-white`;
                default:
                    return tw`bg-white rounded-full text-black`;
            }
        }
    }}
  ${tw`py-sm px-base uppercase text-lg md:text-xl`}
`;

const BannerCTA = ({
    call_to_action_type, call_to_action_label, call_to_action_style, call_to_action_style_tone, call_to_action_url
                   }) => {

    if(call_to_action_url) {
        return (
            <StyledBannerLink
                primary_cta={true}
                href={call_to_action_url.text}
                call_to_action_style={call_to_action_style}
                call_to_action_style_tone={call_to_action_style_tone}
            >
                { call_to_action_label.text}
            </StyledBannerLink>
        )
    }
    return (
        <StyledBannerCTA>
            <span dangerouslySetInnerHTML={{__html: call_to_action_label.html}} />
        </StyledBannerCTA>
    )
};

export default BannerCTA;
