import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import CloudinaryBackgroundImage from "../../CloudinaryBackgroundImage";
import { navigate } from "gatsby";

const StyleBannerWithCaptionContainer = styled.div`
  ${(props) => {
    switch (props.margin_y) {
      case "base":
        return tw`my-base`;
      case "xxsm":
        return tw`my-xxsm`;
      case "xsm":
        return tw`my-xsm`;
      case "sm":
        return tw`my-sm`;
      case "lg":
        return tw`my-lg`;
      case "xlg":
        return tw`my-xlg`;
      case "auto":
        return tw`my-auto`;
      case "0":
        return tw`my-0`;
      default:
        return tw`my-0`;
    }
  }}
  ${(props) => {
    return props.allow_zoom ? tw`cursor-pointer` : tw`cursor-default`;
  }}
`;

const BannerWithCaptionWrapperContainerv4 = styled.div`
  ${tw`flex justify-center xl:h-auto w-full h-full items-center text-center xl:pt-32 lg:pt-16`}
`;

const BannerWithCaptionContainerv4 = styled.div`
  ${tw`flex flex-col h-auto px-4 xl:bg-transparent`}
`;

const HeroV4Image = styled.img`
  ${tw`lg:w-2/6 md:w-3/6 w-5/6 lg:my-16 my-3 mx-auto`};
  max-height: 200px;
`;
const BannerWithCaptionTitleV4 = styled.div`
  ${tw`xl:text-5xl xl:w-1/3 lg:w-2/4 text-3xl text-white xl:py-xsm py-sm font-extrabold mx-auto`}
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.59);
`;

const BannerWithCaptionDescriptionV4 = styled.div`
  ${tw`xl:text-4xl text-3xl font-hairline text-white pb-4`}
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.59);
  font-family: "Gotham Medium";
`;

const BannerCTAContainerV4 = styled.div`
  ${tw`mt-3`}
`;

const HeroSocialContainer = styled.div`
  ${tw`lg:absolute lg:w-12 lg:right-0 lg:bottom-0 lg:mb-32 lg:mr-10`}
`;

const HeroSocialLink = styled.a`
  ${tw`w-12 inline-block lg:mb-2 mr-2`}
`;

const HeroV4 = ({
  image_banner_title,
  image_banner_description,
  bannerImage,
  banner_image_size,
  background_height,
  is_parallax,
  callToAction,
  margin,
  iconOverlay,
  socialLinks,
}) => {
  return (
    <React.Fragment>
      <StyleBannerWithCaptionContainer margin_y={margin}>
        <CloudinaryBackgroundImage
          url={bannerImage}
          background_size={banner_image_size}
          background_height={background_height}
          is_parallax={is_parallax}
        >
          <BannerWithCaptionWrapperContainerv4>
            <BannerWithCaptionContainerv4>
              {iconOverlay && iconOverlay.url ? (
                <HeroV4Image alt="hero" src={iconOverlay.url} />
              ) : null}
              {image_banner_title && image_banner_title.html ? (
                <BannerWithCaptionTitleV4
                  dangerouslySetInnerHTML={{
                    __html: image_banner_title.html,
                  }}
                />
              ) : null}
              {image_banner_description && image_banner_description.html ? (
                <BannerWithCaptionDescriptionV4
                  dangerouslySetInnerHTML={{
                    __html: image_banner_description.html,
                  }}
                />
              ) : null}
              {callToAction && (
                <BannerCTAContainerV4>
                  <button
                    onClick={() => {
                      navigate("/promocion-fase-uno");
                    }}
                    css={css`
                      ${tw`bg-white font-bold text-lg py-4 px-10 mb-4 rounded-lg opacity-75 hover:opacity-100`}
                    `}
                  >
                    Conoce más
                  </button>
                </BannerCTAContainerV4>
              )}

              {socialLinks && socialLinks.length ? (
                <HeroSocialContainer>
                  {socialLinks.map((social) => {
                    return (
                      <HeroSocialLink href={social.link} target="_blank">
                        <img src={social.icon} />
                      </HeroSocialLink>
                    );
                  })}
                </HeroSocialContainer>
              ) : null}
            </BannerWithCaptionContainerv4>
          </BannerWithCaptionWrapperContainerv4>
        </CloudinaryBackgroundImage>
      </StyleBannerWithCaptionContainer>
    </React.Fragment>
  );
};

export default HeroV4;
