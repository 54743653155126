import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "tailwind.macro";
import Heading from "../../Heading/Heading";

const HeroV2Wrapper = styled.section`
  ${tw`text-gray-700`}
`;

const HeroV2Container = styled.div`
  ${tw`mx-auto flex flex-col px-4 py-12 justify-center items-center`}
`;

const HeroV2Image = styled.img`
  ${tw`lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-contain object-center rounded`};
  max-height: 600px;
`;

const HeroV2ContentContainer = styled.div`
  ${tw`w-full md:w-2/3 flex flex-col mb-16 items-center text-center`};
`;

const HeroV2DescriptionContainer = styled.div`
  ${tw`mb-8 py-sm md:py-0 px-sm md:px-0 leading-relaxed text-justify md:text-center`}
`;
const HeroSubTitle = styled.div`
  ${tw`mb-8 leading-relaxed text-center md:text-justify uppercase text-primary-dark`}
`;

const HeroV2 = ({
  bannerTitle,
  bannerSubtitle,
  bannerDescription,
  bannerImage,
}) => {
  console.log("bannerSubtitle: ", bannerSubtitle);
  return (
    <HeroV2Wrapper>
      <HeroV2Container>
        {bannerSubtitle && (
          <HeroSubTitle
            dangerouslySetInnerHTML={{ __html: bannerSubtitle.html }}
          />
        )}

        <HeroV2Image alt="hero" src={bannerImage} />
        <HeroV2ContentContainer>
          <Heading heading={bannerTitle} />
          {bannerDescription && (
            <HeroV2DescriptionContainer
              dangerouslySetInnerHTML={{ __html: bannerDescription.html }}
            />
          )}
        </HeroV2ContentContainer>
      </HeroV2Container>
    </HeroV2Wrapper>
  );
};

export default HeroV2;
